import axios from "axios"
import { useEffect, useState } from "react"
import { Route, Navigate, useParams, createBrowserRouter, createRoutesFromElements } from "react-router-dom"
import { CircularProgress } from "@mui/material"
import Page from "components/Page"
import SecretCreate from "components/SecretCreate"
import SecretDetails from "components/SecretDetails"
import SecretView from "components/SecretView"
import SecretNotFound from "components/SecretNotFound"

function FindSecret () {

    const params = useParams ()
    const [ response, setResponse ] = useState ( false )
    const { id } = params
    const isLoading = response === false

    useEffect ( () => {
        axios ({ method: "HEAD", url: `${process.env.API_ENDPOINT}/secrets/${id}` })
            .then ( res => setResponse ( res.status ) )
            .catch ( err => setResponse ( err.response.status ) )
    }, [] )

    if ( isLoading ) {
        return <Page title="Loading Secret" >
            <CircularProgress/>
        </Page>
    }

    if ( response !== 200 ) {
        return <Page title="Secret Not Found" >
            <SecretNotFound/>
        </Page>
    }

    return <Page title="View Secret" >
        <SecretView id={id} />
    </Page>

}

export default createBrowserRouter (
    createRoutesFromElements (
        <Route>
            <Route path="/create" element={<Page title="Create Secret" ><SecretCreate/></Page>} />
            <Route path="/details" element={<Page title="Secret Details" ><SecretDetails/></Page>} />
            <Route path="/secret/:id" element={<FindSecret/>} />
            <Route path="*" element={<Navigate to="/create" replace />} />
        </Route>
    )
)