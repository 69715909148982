import { useEffect, useContext } from "react"
import { Box, Stack, Paper, Toolbar, IconButton } from "@mui/material"
import { Breadcrumbs, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import PreferenceContext from "contexts/PreferenceContext"
import LogoSrc from "assets/logo.svg"
import TerminalIcon from "@mui/icons-material/Terminal"
import LightModeIcon from "@mui/icons-material/LightMode"
import DarkModeIcon from "@mui/icons-material/DarkMode"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"

export default function ({ children, title = "Unknown" }) {

    const navigate = useNavigate ()
    const preference = useContext ( PreferenceContext )
    
    useEffect (() => { document.title = `JetRails | ${title}` }, [title])

    return <Stack sx={{ height: "100%" }} >
        <Paper>
            <Toolbar
                component={Stack}
                direction="row"
                spacing={2}
                justifyContent="space-between"
                sx={{ background: t => t.palette.blue.main }} >
                <img
                    src={LogoSrc}
                    height={24}
                    onClick={() => navigate ("/")}
                    style={{ cursor: "pointer" }}
                />
                <Stack direction="row" spacing={2} >
                    <IconButton onClick={() => window.open ("https://github.com/jetrails/jrctl", "_blank")} >
                        <TerminalIcon sx={{ color: "white" }} />
                    </IconButton>
                    <IconButton onClick={() => preference.setThemeMode ( preference.themeMode === "light" ? "dark" : "light" )} >
                        {
                            preference.themeMode === "light"
                            ? <LightModeIcon sx={{ color: "white" }} />
                            : <DarkModeIcon sx={{ color: "white" }} />
                        }
                    </IconButton>
                </Stack>
            </Toolbar>
        </Paper>
        <Breadcrumbs
            component={Box}
            separator={<NavigateNextIcon fontSize="small" />}
            sx={{ p: 2 }} >
            <Typography sx={{ fontWeight: "bold" }} >JetRails OTS</Typography>
            <Typography>{title}</Typography>
        </Breadcrumbs>
        <Stack sx={{ height: "100%", overflow: "scroll", alignItems: "stretch" }} >
            <Box sx={{ px: { xs: 0, sm: 2 }, py: 4, display: "flex", justifyContent: "center" }} >
                {children}
            </Box>
        </Stack>
    </Stack>

}
