import { useState, createContext } from "react"

const PreferenceContext = createContext ( null )
PreferenceContext.displayName = "PreferenceContext"

const PreferenceProvider = ({ children, duration = 5000 }) => {

    const [themeMode, setThemeMode] = useState ( localStorage.getItem ("themeMode") || "light" )

    if ( process.env.NODE_ENV !== "production" ) {
        console.log ( "PreferenceContext:", { themeMode } )
    }

    function wrapSet ( key ) {
        return value => {
            localStorage.setItem ( key, value )
            return setThemeMode ( value )
        }
    }

    return <PreferenceContext.Provider
        value={{
            themeMode, setThemeMode: wrapSet ( "themeMode" ),
        }} >
        {children}
    </PreferenceContext.Provider>

}

export { PreferenceProvider }
export default PreferenceContext
