import "@fontsource/roboto-mono"
import "@fontsource/poppins"
import { createTheme } from "@mui/material/styles"

export default mode => {
	const isDarkMode = mode === "dark"
	const base = createTheme ({ palette: { mode } })
	const { augmentColor } = base.palette
	const green = augmentColor ({ color: { main: "#89BA40" } })
	const blue = augmentColor ({ color: { main: "#111827" } })
	const theme = createTheme ({
		typography: {
			fontFamily: "Poppins",
			fontSize: 14,
		},
		palette: {
			mode,
			blue,
			green,
			primary: isDarkMode ? green : blue,
			secondary: isDarkMode ? green : green,
			background: {
				default: isDarkMode ? "#0B0F19" : "#FAFAFA",
				paper: isDarkMode ? "#111827" : "#FFFFFF",
			},
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					html: {
						height: "100%",
						width: "100%",
					},
					body: {
						margin: 0,
						height: "100%",
						width: "100%",
					},
					"#main": {
						height: "100%",
						width: "100%",
					},
					"::selection": {
						background: "#89BA40",
						color: "#000000",
					},
				},
			},
			MuiFilledInput: {
				styleOverrides: {
					root: {
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
					},
				},
				defaultProps: {
					disableUnderline: true,
					margin: "dense",
					spellCheck: "false",
				},
			},
		},
	})
	if ( process.env.NODE_ENV !== "production" ) {
		console.log ( "MuiTheme:", theme )
	}
	return theme
}