"use string"

module.exports = length => {
	const alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
	var password = ""
	while ( password.length < length ) {
		randomIndex = Math.floor ( Math.random () * alphabet.length )
		password += alphabet [randomIndex]
	}
	return password
}
