import { useState, createContext } from "react"

const DataContext = createContext ( null )
DataContext.displayName = "DataContext"

const DataProvider = ({ children, duration = 5000 }) => {

    const [data, setData] = useState ({})

    if ( process.env.NODE_ENV !== "production" ) {
        console.log ( "DataContext:", data )
    }

    return <DataContext.Provider
        value={{ data, setData }} >
        {children}
    </DataContext.Provider>

}

export { DataProvider }
export default DataContext
