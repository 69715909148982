import { useState, createContext } from "react"
import { Alert, AlertTitle, Snackbar } from "@mui/material"

const AlertContext = createContext ( null )
AlertContext.displayName = "AlertContext"

const AlertProvider = ({ children, duration = 5000 }) => {

    const [open, setOpen] = useState ( false )
    const [title, setTitle] = useState ("")
    const [content, setContent] = useState ("")
    const [isError, setIsError] = useState ( false )

    if ( process.env.NODE_ENV !== "production" ) {
        console.log ( "AlertContext:", { open, title, content, isError } )
    }

    return <AlertContext.Provider
        value={{
            success: ( content ) => {
                setIsError ( false )
                setOpen ( true )
                setTitle ("Success!")
                setContent ( content )
                setTimeout ( () => {
                    setOpen ( false )
                }, duration )
            },
            error: ( title, response ) => {
                setIsError ( true )
                setOpen ( true )
                setTitle ( title )
                setContent ( Object.values ( response?.payload )?.pop () || response?.message )
                setTimeout ( () => {
                    setOpen ( false )
                }, duration )
            },
        }} >
        {children}
        <Snackbar
            open={!!open}
            autoHideDuration={duration}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }} >
            <Alert
                elevation={1}
                onClose={() => setOpen ( false )}
                severity={isError ? "error" : "success"} >
                <AlertTitle>{title}</AlertTitle>
                {content}
            </Alert>
        </Snackbar>
    </AlertContext.Provider>

}

export { AlertProvider }
export default AlertContext
