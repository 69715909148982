import _extends from "@babel/runtime/helpers/esm/extends";
import { moveHighlight, listReducer, ListActionTypes } from '../useList';
import { SelectActionTypes } from './useSelect.types';
export default function selectReducer(state, action) {
  const {
    open
  } = state;
  const {
    context: {
      selectionMode
    }
  } = action;
  switch (action.type) {
    case SelectActionTypes.buttonClick:
      {
        var _state$selectedValues;
        const itemToHighlight = (_state$selectedValues = state.selectedValues[0]) != null ? _state$selectedValues : moveHighlight(null, 'start', action.context);
        return _extends({}, state, {
          open: !open,
          highlightedValue: !open ? itemToHighlight : null
        });
      }
    case SelectActionTypes.buttonArrowKeyDown:
      if (action.key === 'ArrowDown') {
        var _state$selectedValues2;
        const itemToHighlight = (_state$selectedValues2 = state.selectedValues[0]) != null ? _state$selectedValues2 : moveHighlight(null, 'start', action.context);
        return _extends({}, state, {
          open: true,
          highlightedValue: itemToHighlight
        });
      }
      if (action.key === 'ArrowUp') {
        var _state$selectedValues3;
        const itemToHighlight = (_state$selectedValues3 = state.selectedValues[0]) != null ? _state$selectedValues3 : moveHighlight(null, 'end', action.context);
        return _extends({}, state, {
          open: true,
          highlightedValue: itemToHighlight
        });
      }
      break;
    default:
      break;
  }
  const newState = listReducer(state, action);
  switch (action.type) {
    case ListActionTypes.keyDown:
      if (selectionMode === 'single' && (action.event.key === 'Enter' || action.event.key === ' ' || action.event.key === 'Escape')) {
        return _extends({}, newState, {
          open: false
        });
      }
      break;
    case ListActionTypes.itemClick:
      if (selectionMode === 'single') {
        return _extends({}, newState, {
          open: false
        });
      }
      break;
    case ListActionTypes.blur:
      return _extends({}, newState, {
        open: false
      });
    default:
      return newState;
  }
  return newState;
}