import copy from "copy-to-clipboard"
import axios from "axios"
import { useState, useEffect, useContext } from "react"
import { Card, CardContent, Stack, Typography, IconButton, TextField, Button } from "@mui/material"
import { useTheme, useMediaQuery } from "@mui/material"
import { useNavigate } from "react-router-dom"
import AlertContext from "contexts/AlertContext"
import ClipboardIcon from "@mui/icons-material/Assignment"
import NewIcon from "@mui/icons-material/Add"
import FullscreenEnterIcon from "@mui/icons-material/Fullscreen"
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit"
import SecretPrompt from "components/SecretPrompt"

export default function ({ id }) {

    const alert = useContext ( AlertContext )
    const navigate = useNavigate ()
    const theme = useTheme ()
    const isSmall = useMediaQuery ( theme.breakpoints.down ("sm") )
    const [fullScreen, setFullScreen] = useState ( false )
    const [password, setPassword] = useState ("")
    const [request, setRequest] = useState ({ id, password, ts: 0 })
    const [response, setResponse] = useState ( null )
    const isLoading = response === null

    useEffect ( () => {
        if ( response === false ) return
        axios ({ method: "GET", url: `${process.env.API_ENDPOINT}/secrets/${request?.id}`, params: { password: request?.password } })
            .then ( res => setResponse ( res ) || res )
            .catch ( err => setResponse ( err.response ) || err.response )
            .then ( res => {
                if ( [ 422, 401 ].includes ( res?.status ) ) {
                    alert.error ("Failed To Decrypt Secret", { payload: { message: "invalid password passed" } } )
                }
            })
    }, [ request.ts ] )

    if ( [ 400, 422, 401 ].includes ( response?.status ) ) {
        return <SecretPrompt
            value={password}
            onChange={val => setPassword ( val )}
            onSubmit={password => setRequest ({ id, password, ts: new Date ().getTime () })}
            isLoading={isLoading}
        />
    }

    return <Card sx={{ width: "100%", maxWidth: t => fullScreen ? "initial" : t.breakpoints.values.sm }} >
        <CardContent component={Stack} spacing={2} >
            <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center" >
                <Typography variant="h6" >View One-Time Secret</Typography>
                {
                    !isSmall && <IconButton onClick={() => setFullScreen ( !fullScreen )} >
                        { fullScreen ? <FullscreenExitIcon color="primary" /> : <FullscreenEnterIcon color="primary" /> }
                    </IconButton>
                }
            </Stack>
            <TextField
                fullWidth
                multiline
                readOnly
                minRows={8}
                maxRows={Infinity}
                variant="filled"
                label="Secret"
                value={response?.data}
                onFocus={event => event.target.select () }
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    style: {
                        fontFamily: "Roboto Mono",
                        whiteSpace: "pre",
                        overflow: "auto",
                    },
                }}
            />
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2} >
                <Button
                    fullWidth
                    autoFocus
                    disabled={isLoading}
                    size="large"
                    color="primary"
                    variant="outlined"
                    endIcon={<ClipboardIcon color={isLoading ? "disabled" : "primary"} />}
                    onClick={() => {
                        copy ( response?.data )
                        alert.success ("copied to clipboard")
                    }} >
                    Copy Secret
                </Button>
                <Button
                    fullWidth
                    disabled={isLoading}
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={() => navigate ("/")}
                    endIcon={<NewIcon color={( isLoading ) ? "disabled" : "primary"} />} >
                    New Secret
                </Button>
            </Stack>
        </CardContent>
    </Card>

}
