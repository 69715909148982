import ReactDOM from "react-dom"
import CssBaseline from "@mui/material/CssBaseline"
import theme from "base/theme"
import { ThemeProvider } from "@mui/material/styles"
import { RouterProvider } from "react-router-dom"
import { AlertProvider } from "contexts/AlertContext"
import { DataProvider } from "contexts/DataContext"
import PreferenceContext, { PreferenceProvider } from "contexts/PreferenceContext"
import router from "base/router"

ReactDOM.render (
    <PreferenceProvider>
        <PreferenceContext.Consumer>
        {
            provider => <ThemeProvider theme={theme ( provider.themeMode )} >
                <DataProvider>
                    <AlertProvider>
                        <CssBaseline/>
                        <RouterProvider router={router} />
                    </AlertProvider>
                </DataProvider>
            </ThemeProvider>
        }
        </PreferenceContext.Consumer>
    </PreferenceProvider>,
	document.querySelector ("#main"),
)
