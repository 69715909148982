import { Card, CardContent, Stack, Typography, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import NewIcon from "@mui/icons-material/Add"

export default function () {

    const navigate = useNavigate ()

    return <Card sx={{ width: "100%", maxWidth: t => t.breakpoints.values.sm }} >
        <CardContent component={Stack} spacing={2} >
            <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center" >
                <Typography variant="h6" >
                    Secret Not Found
                </Typography>
            </Stack>
            <Typography variant="body1" >
                Either secret id is malformed in URL or secret has already been viewed.
            </Typography>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2} >
                <Button
                    fullWidth
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={() => navigate ("/")}
                    endIcon={<NewIcon color="inherit" />} >
                    New Secret
                </Button>
            </Stack>
        </CardContent>
    </Card>

}
