import axios from "axios"
import moment from "moment"
import { useState, useContext, useState } from "react"
import { useNavigate, Navigate } from "react-router-dom"
import { Card, CardContent, Stack, Typography } from "@mui/material"
import { TextField, Button, InputAdornment, IconButton } from "@mui/material"
import NewIcon from "@mui/icons-material/Add"
import LinkIcon from "@mui/icons-material/Link"
import TerminalIcon from "@mui/icons-material/Terminal"
import DestroyIcon from "@mui/icons-material/Whatshot"
import VisibleIcon from "@mui/icons-material/Visibility"
import NotVisibleIcon from "@mui/icons-material/VisibilityOff"
import CopyButton from "components/CopyButton"
import CopyIconButton from "components/CopyIconButton"
import AlertContext from "contexts/AlertContext"
import DataContext from "contexts/DataContext"

export default function () {

    const data = useContext ( DataContext )
    const { id, password, ttl } = data.data
    if ( !id ) return <Navigate to="/" />
    
    const navigate = useNavigate ()
    const alert = useContext ( AlertContext )
    const secretUrl = `${process.env.ENDPOINT}/secret/${id}`
    const secretCli = `jrctl secret read ${password ? `-p ${password} ` : ""}${id}`
    const [response, setResponse] = useState(null)
    const [showPassword, setShowPassword] = useState ( false )
    const isLoading = response === false

    async function onDestroy () {
        setResponse ( false )
        const response = await axios ({
            method: "DELETE",
            url: `${process.env.API_ENDPOINT}/secrets/${id}`,
        })
        .catch ( error => {
            return error?.response 
        })
        setResponse ( response )
        if ( response?.data?.code === 200 ) {
            alert.success ("secret destroyed successfully")
            navigate ("/")
        } else {
            alert.error ("Failed To Destroy Secret!", response?.data )
        }
    }

    return <Card sx={{ width: "100%", maxWidth: t => t.breakpoints.values.sm }} >
        <CardContent component={Stack} spacing={2} >
            <Typography variant="h6" >
                One-Time Secret Details
            </Typography>
            <Typography variant="body1" >
                This secret will expire in <b>{moment.duration (ttl, "seconds").humanize ()}</b> unless you burn it.
            </Typography>
            <TextField
                fullWidth
                readOnly
                autoFocus
                variant="filled"
                label="Secret URL"
                value={secretUrl}
                onFocus={event => event.target.select () }
                InputProps={{
                    startAdornment: <InputAdornment position="start"><LinkIcon/></InputAdornment>,
                    endAdornment: <InputAdornment position="end" >
                        <CopyIconButton value={secretUrl} />
                    </InputAdornment>,
                }}
            />
            <TextField
                fullWidth
                readOnly
                variant="filled"
                label="CLI Command"
                value={secretCli}
                onFocus={event => event.target.select () }
                InputProps={{
                    startAdornment: <InputAdornment position="start"><TerminalIcon/></InputAdornment>,
                    endAdornment: <InputAdornment position="end" >
                        <CopyIconButton value={secretCli} />
                    </InputAdornment>,
                }}
            />
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2} >
                <TextField
                    fullWidth
                    readOnly
                    variant="filled"
                    label="Identifier"
                    value={id}
                    onFocus={event => event.target.select () }
                    InputProps={{
                        endAdornment: <InputAdornment position="end" >
                            <CopyIconButton value={id} />
                        </InputAdornment>,
                    }}
                />
                {
                    password && <TextField
                        fullWidth
                        readOnly
                        type={showPassword ? "text" : "password"}
                        variant="filled"
                        label="Password"
                        value={password}
                        onFocus={event => event.target.select () }
                        InputProps={{
                            endAdornment: <InputAdornment position="end" >
                                <IconButton
                                    disabled={isLoading}
                                    onClick={() => setShowPassword ( !showPassword )} >
                                    {showPassword ? <VisibleIcon/> : <NotVisibleIcon/>}
                                </IconButton>
                                <CopyIconButton value={password} />
                            </InputAdornment>,
                        }}
                    />
                }
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2} >
                <CopyButton title="Copy URL" value={secretUrl} />
                <Button
                    fullWidth
                    disabled={isLoading}
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={() => navigate ("/")}
                    endIcon={<NewIcon color={isLoading ? "disabled" : "primary"} />} >
                    New Secret
                </Button>
            </Stack>
            <Button
                fullWidth
                disabled={isLoading}
                size="large"
                color="error"
                variant="outlined"
                onClick={onDestroy}
                endIcon={<DestroyIcon color={isLoading ? "disabled" : "error"} />} >
                Destroy Secret
            </Button>
        </CardContent>
    </Card>

}
