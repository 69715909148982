import copy from "copy-to-clipboard"
import { useEffect, useState, useContext } from "react"
import { Button } from "@mui/material"
import ClipboardIcon from "@mui/icons-material/Assignment"
import AlertContext from "contexts/AlertContext"

export default function ({ value, title = "Copy" }) {

    var timeout = null
    const [copied, setCopied] = useState ( false )
    const alert = useContext ( AlertContext )

    useEffect (() => {
        if ( !copied ) return
        timeout = setTimeout ( () => setCopied ( false ), 1000 )
        return () => clearTimeout ( timeout )
    }, [ copied ])

    function onCopy () {
        copy ( value )
        setCopied ( true )
        alert.success ("copied to clipboard")
    }

    return <Button
        fullWidth
        color="primary"
        variant="outlined"
        disabled={copied}
        endIcon={<ClipboardIcon/>}
        onClick={onCopy} >
        {title}
    </Button>

}
