import axios from "axios"
import moment from "moment"
import mkpass from "utils/mkpass"
import { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Card, CardContent, Stack, Typography, useTheme, useMediaQuery } from "@mui/material"
import { TextField, MenuItem, Button, IconButton, InputAdornment } from "@mui/material"
import SendIcon from "@mui/icons-material/Send"
import FullscreenEnterIcon from "@mui/icons-material/Fullscreen"
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit"
import VisibleIcon from "@mui/icons-material/Visibility"
import NotVisibleIcon from "@mui/icons-material/VisibilityOff"
import GeneratePasswordIcon from "@mui/icons-material/Cached"
import AlertContext from "contexts/AlertContext"
import DataContext from "contexts/DataContext"

export default function () {

    const navigate = useNavigate ()
    const theme = useTheme ()
    const isSmall = useMediaQuery ( theme.breakpoints.down ("sm") )
    const alert = useContext ( AlertContext )
    const data = useContext ( DataContext )
    const [secret, setSecret] = useState("")
    const [ttl, setTtl] = useState(1 * 24 * 60 * 60)
    const [password, setPassword] = useState("")
    const [fullScreen, setFullScreen] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [response, setResponse] = useState(null)
    const isLoading = response === false

    async function onCreate () {
        setResponse ( false )
        const response = await axios ({
            method: "POST",
            url: `${process.env.API_ENDPOINT}/secrets`,
            data: {
                secret,
                ttl,
                password,
            }
        })
        .catch ( error => {
            return error?.response 
        })
        setResponse ( response )
        if ( response?.data?.code === 200 ) {
            alert.success ("secret created successfully")
            data.setData ({
                id: response?.data?.payload?.id,
                ttl: response?.data?.payload?.ttl,
                password: response?.data?.payload?.password,
            })
            navigate ("/details")
        }
        else {
            alert.error ("Failed To Create Secret!", response?.data )
        }
    }

    return <Card sx={{ width: "100%", maxWidth: t => fullScreen ? "initial" : t.breakpoints.values.sm }} >
        <CardContent component={Stack} spacing={2} >
            <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center" >
                <Typography variant="h6" >
                    Create One-Time Secret
                </Typography>
                {
                    !isSmall && <IconButton onClick={() => setFullScreen ( !fullScreen )} >
                        { fullScreen ? <FullscreenExitIcon color="primary" /> : <FullscreenEnterIcon color="primary" /> }
                    </IconButton>
                }
            </Stack>
            <Typography variant="body1" >
                Once created, the secret can only be viewed once and is destroyed on retrieval.
                The secret's recipient has <b>{moment.duration (ttl, "seconds").humanize ()}</b> to view the secret before it expires.
            </Typography>
            <TextField
                fullWidth
                multiline
                autoFocus
                disabled={isLoading}
                minRows={8}
                maxRows={Infinity}
                variant="filled"
                label={`Secret${secret.length < 1 ? "" : ` (${32768 - secret.length} chars left)`}`}
                value={secret}
                onChange={e => setSecret(e.target.value)}
                inputProps={{
                    style: {
                        fontFamily: "Roboto Mono",
                        whiteSpace: "pre",
                        overflow: "auto",
                    },
                }}
            />
            <TextField
                fullWidth
                select
                disabled={isLoading}
                variant="filled"
                label="Expires In"
                value={ttl}
                onChange={e => setTtl(e.target.value)} >
                <MenuItem value={1 * 60 * 60} >1 Hour</MenuItem>
                <MenuItem value={6 * 60 * 60} >6 Hour</MenuItem>
                <MenuItem value={12 * 60 * 60} >12 Hour</MenuItem>
                <MenuItem value={1 * 24 * 60 * 60} >1 Day</MenuItem>
                <MenuItem value={3 * 24 * 60 * 60} >3 Day</MenuItem>
                <MenuItem value={5 * 24 * 60 * 60} >5 Days</MenuItem>
                <MenuItem value={7 * 24 * 60 * 60} >7 Days</MenuItem>
            </TextField>
            <TextField
                fullWidth
                disabled={isLoading}
                variant="filled"
                label="Password (Optional)"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={e => setPassword(e.target.value)}
                InputProps={{
                    endAdornment: <InputAdornment position="end" >
                        <IconButton
                            disabled={isLoading}
                            onClick={() => setPassword ( mkpass ( 32 ) ) || setShowPassword ( true )} >
                            <GeneratePasswordIcon/>
                        </IconButton>
                        <IconButton
                            disabled={isLoading}
                            onClick={() => setShowPassword ( !showPassword )} >
                            {showPassword ? <VisibleIcon/> : <NotVisibleIcon/>}
                        </IconButton>
                    </InputAdornment>,
                }}
                onKeyPress={e => {
                    if ( e.key === "Enter" ) {
                        e.preventDefault()
                        return onCreate ()
                    }
                }}
            />
            <Button
                fullWidth
                disabled={isLoading}
                size="large"
                color="primary"
                variant="outlined"
                onClick={onCreate}
                endIcon={<SendIcon color={isLoading ? "disabled" : "primary"} />} >
                Create Secret
            </Button>
        </CardContent>
    </Card>

}
