import * as React from 'react';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import { CompoundComponentContext } from './useCompound';
export function useCompoundItem(id, itemMetadata, missingKeyGenerator) {
  const context = React.useContext(CompoundComponentContext);
  if (context === null) {
    throw new Error('useCompoundItem must be used within a useCompoundParent');
  }
  const {
    registerItem
  } = context;
  const [registeredId, setRegisteredId] = React.useState(id);
  useEnhancedEffect(() => {
    const {
      id: returnedId,
      deregister
    } = registerItem(id, itemMetadata, missingKeyGenerator);
    setRegisteredId(returnedId);
    return deregister;
  }, [registerItem, itemMetadata, missingKeyGenerator, id]);
  return {
    id: registeredId,
    index: registeredId !== undefined ? context.getItemIndex(registeredId) : -1,
    totalItemCount: context.totalSubitemCount
  };
}