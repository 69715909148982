import { Card, CardContent, Stack, Typography, TextField, Button } from "@mui/material"

export default function ({ value, onChange, onSubmit, isLoading }) {

    return <Card sx={{ width: "100%", maxWidth: t => t.breakpoints.values.sm }} >
        <CardContent component={Stack} spacing={2} >
            <Typography variant="h6" >Decrypt Secret</Typography>
            <Typography variant="body1" >
                This secret is password protected, please enter the password in order to decrypt this secret.
            </Typography>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2} >
                <TextField
                    fullWidth
                    autoFocus
                    type="password"
                    disabled={isLoading}
                    size="large"
                    color="primary"
                    variant="filled"
                    label="Password"
                    value={value}
                    onChange={e => onChange ( e.target.value )}
                    onKeyPress={e => e.key === "Enter" && onSubmit ( value )}
                />
                <Button
                    disabled={isLoading || value === ""}
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={() => onSubmit ( value )} >
                    Decrypt
                </Button>
            </Stack>
        </CardContent>
    </Card>

}
